<template>
  <modal
    :name="modalName"
    :width="600"
    :adaptive="true"
    :clickToClose="false"
    height="auto"
    class="modal--input-phone"
  >
    <div class="modal--wrapper">
      <div class="back--wrapper" v-if="$mq === 'xs'">
        <div class="back--button" @click="close()">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        {{ $t('addListing.phoneForm.title') }}
      </div>
      <section class="modal--container">
        <div class="title--text" v-if="$mq !== 'xs'">
          {{ $t('addListing.phoneForm.title') }}
        </div>
        <b-card>
          <section-loading :show="loading" />
          <div class="wrapper--title">
            <h4>{{ $t('addListing.phoneForm.subtitle') }}</h4>
            <hr />
          </div>
          <div role="alert" class="alert alert-warning mb0">
            {{ $t('addListing.phoneForm.message') }}
          </div>
          <div class="wrapper--input">
            <div :class="{ 'has-error': validation.hasError('phoneNumber') }" class="form-group">
              <label for="bankFormPhoneNumber">{{ $t('bankForm.phoneNumber') }}</label>
              <client-only>
                <vue-tel-input
                  id="bankFormPhoneNumber"
                  :value="phoneNumber ? phoneNumber : ''"
                  @input="phoneInput"
                  :validCharactersOnly="true"
                  defaultCountry="sg"
                />
              </client-only>
              <span class="val-error">{{ validation.firstError('phoneNumber') }}</span>
            </div>
            <div class="wrapper--button text-right">
              <button
                class="btn btn-primary d-block"
                style="width: 100%"
                id="submitPhone"
                @click="postData"
              >
                {{ $t('profile.savePhone') }}
              </button>
            </div>
          </div>
        </b-card>
      </section>
    </div>
  </modal>
</template>

<script>
import { Validator } from 'simple-vue-validator';
// import Multiselect from 'vue-multiselect';
const SectionLoading = () => import('@/components/content-loading/section-loading');

import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
export default {
  props: {
    showNotNowBtn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalName: 'modal-input-phone',
      loading: false,
    };
  },
  computed: {
    phoneNumber() {
      return this.$store.state.v2.bankForm.phoneNumber;
    },
  },
  validators: {
    phoneNumber(value) {
      return Validator.value(value).required(this.$i18n.t('errors.phone.required'));
    },
  },
  components: {
    SectionLoading,
    VueTelInput,
  },
  methods: {
    phoneInput(phone, phoneObject) {
      if (phoneObject && phoneObject.countryCode) {
        this.phoneCountryCode = phoneObject.countryCode;
      } else {
        this.phoneCountryCode = null;
      }
      this.$store.commit('v2/bankForm/SET_PHONE_NUMBER', phone);
    },

    async postData() {
      try {
        this.loading = true;
        const isValid = await this.$validate();
        if (isValid) {
          await this.$http.post('/api/v2/profile/phone_number/update', {
            phone_number: this.phoneNumber,
            phone_country_code: this.phoneCountryCode,
          });
          // await this.$store.dispatch('v2/bankForm/postData');
          this.$swal(this.$t('general.success'), this.$t('booking.swal.phone.success'), 'success');
          this.$store.dispatch('global/fetch');
          this.postDone();
          // eslint-disable-next-line no-empty
        }
      } catch (e) {
        console.log('errornya cuk', e);
      } finally {
        this.loading = false;
      }
    },
    postDone() {
      this.$emit('postDone');
      this.$modal.hide(this.modalName);
    },
    close() {
      this.$emit('notNow');
      this.$modal.hide(this.modalName);
    },
  },
};
</script>

<style></style>
