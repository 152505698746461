<template>
  <div class="row">
    <loader :show="isLoading" />
    <div class="col-12 col-md-4 listing--form-left">
      <client-only>
        <div v-if="$mq !== 'xs'">
          <div class="title--text">
            {{ $t('addListing.sideTitle') }}
          </div>
          <div class="desc">
            {{ $t('addListing.sideMsg') }}
          </div>
        </div>
      </client-only>
      <v-stepper v-model="currentStep" vertical class="stepper--left">
        <v-stepper-step :complete="currentStep > 1" :step="1" class="stepper--step">
          {{ $t('addListing.step1') }}
        </v-stepper-step>
        <v-stepper-step :step="2" class="stepper--step">
          {{ $t('addListing.step2') }}
        </v-stepper-step>
      </v-stepper>
    </div>
    <div class="col-12 col-md-8 listing--form-right">
      <v-stepper v-model="currentStep" vertical class="stepper--right">
        <v-stepper-content step="1" class="stepper--content">
          <sect1-form ref="sect1" />
        </v-stepper-content>
        <v-stepper-content step="2" class="stepper--content">
          <sect2-form ref="sect2" />
        </v-stepper-content>
      </v-stepper>
    </div>
    <input-bank-account-modal />
    <input-phone-number-modal />
    <modal-success />
  </div>
</template>

<script>
const ModalSuccess = () => import('@/components/listing-form/modals/modal-success.vue');
const InputBankAccountModal = () =>
  import('@/components/bank-account/modals/input-bank-account-modal');
const Sect1Form = () => import('@/components/listing-form/sect1/index');
const Sect2Form = () => import('@/components/listing-form/sect2/index');
const Loader = () => import('@/components/content-loading/page-loading.vue');
import { mapState } from 'vuex';
import InputPhoneNumberModal from '@/components/profile/modals/input-phone-modal.vue';

export default {
  name: 'listing-form',
  props: [
    'formProp', // add || edit
  ],
  components: {
    Sect1Form,
    Sect2Form,
    Loader,
    ModalSuccess,
    InputBankAccountModal,
    InputPhoneNumberModal,
  },
  computed: {
    ...mapState({
      form: state => state.v2.listingForm.form,
      isLoading: state => state.v2.listingForm.isLoading,
    }),
    currentStep: {
      get() {
        return this.$store.state.v2.listingForm.formCurrentStep;
      },
      set(value) {
        this.$store.commit('v2/listingForm/SET_FORM_CURRENT_STEP', value);
      },
    },
  },
  async created() {
    this.$store.commit('v2/listingForm/sect1/location/SET_SELECTED_COUNTRY', 1); //indo
    let self = this;
    this.$store.commit('v2/listingForm/SET_FORM', self.formProp);

    if (self.form === 'add') {
      this.setLoading(true);
      await this.$store.dispatch('v2/listingForm/fetchBaseData');
      this.setLoading(false);
    }
  },
  methods: {
    setLoading(value) {
      this.$store.commit('v2/listingForm/SET_LOADING', value);
    },
  },
  async mounted() {
    const resultCheck = await this.$store.dispatch('v2/profile/checkUserCanAddListing');
    if (!resultCheck) {
      console.log('masuk siinii');
      this.$modal.show('modal-input-phone');
    }
    const resultCheckId = await this.$store.dispatch('v2/profile/checkUserCanAddListingUserId');
    if (!resultCheckId) {
      this.$swal({
        title: 'Feature Coming Soon',
        text:
          'Thank you for your interest in Propfix.sg. Currently, adding listing to our platform is only available for Exclusive Partners. If you are interested to join Propfix.sg as Partners, please email us at partnership@propfix.sg.',
        type: 'warning',
        showConfirmButton: false,
        allowOutsideClick: true,
        // eslint-disable-next-line no-unused-vars
      }).then(result => {
        this.$router.replace('/');
      });
    }
  },
};
</script>

<style scoped></style>
